<template>
  <div ref="modalContainer"></div>
  <a-card class="content-wrapper">
    <template #title>
      <CardHeader></CardHeader>
    </template>
    <div class="body-content">
      <a-form layout="inline">
        <a-form-item style="padding-bottom: 10px" label="订单号">
          <a-input
            v-model:value="params.payOrderId"
            placeholder="输入订单号"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item style="padding-bottom: 10px" label="退款申请时间">
          <a-range-picker
            style="width: 400px"
            v-model:value="params.auditTimeArr"
            :show-time="{ format: 'HH:mm:ss' }"
            format="YYYY-MM-DD HH:mm:ss"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            :placeholder="['开始时间', '结束时间']"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="doQuery">
            <template #icon>
              <SearchOutlined />
            </template>
            查询
          </a-button>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="download">
            <template #icon>
              <ExportOutlined />
            </template>
            导出
          </a-button>
        </a-form-item>
        <!--        <a-form-item>-->
        <!--          <a-button class="btn-success" @click="showAddModal">-->
        <!--            <template #icon>-->
        <!--              <PlusCircleOutlined />-->
        <!--            </template>-->
        <!--            申请退款-->
        <!--          </a-button>-->
        <!--        </a-form-item>-->
      </a-form>
      <div style="padding: 4px 0">
        <a-table
          row-key="id"
          table-layout="fixed"
          :data-source="dataList.list"
          :columns="columns"
          :loading="loading"
          :pagination="false"
          :scroll="{ y: 500 }"
          bordered
        >
          <template #payChannel="{ text }">
            {{ getChannel(text) }}
          </template>
          <template #auditState="{ text }">
            <a-tag v-if="text == -1" color="red">不通过</a-tag>
            <a-tag v-if="text == 0" color="warning">待审核</a-tag>
            <a-tag v-if="text == 1" color="success">审核通过</a-tag>
          </template>
          <template #state="{ text }">
            <a-tag v-if="text == -1" color="red">退款失败</a-tag>
            <a-tag v-if="text == 0" color="warning">待退款</a-tag>
            <a-tag v-if="text == 1" color="processing">退款中</a-tag>
            <a-tag v-if="text == 2" color="success">退款成功</a-tag>
          </template>
          <template #paidAmount="{ text }">
            {{ money(text) }}
          </template>
          <template #refundAmount="{ text }">
            {{ money(text) }}
          </template>
          <template #plateNumber="{ record }">
            <a-tag :color="plateColorStyle(record.plateColor)">
              <span
                :style="{
                  color: record.plateColor == 4 ? '#001510' : '#FFFFFF',
                }"
                >{{ record.plateNumber }}</span
              ></a-tag
            >
          </template>
          <template #operation="{ record }">
            <div>
              <TipButton
                v-if="record.auditState == 0"
                perm="info:refundRecord:audit"
                btn-class="btn-success"
                title="审核"
                @btnClick="showEditModal(record.id)"
              >
                <template #default>
                  <AuditOutlined />
                </template>
              </TipButton>
              <TipButton
                v-else
                perm="info:refundRecord:detail"
                title="审核记录"
                @btnClick="showDetailModal(record.id)"
              >
                <template #default>
                  <ContainerOutlined />
                </template>
              </TipButton>
            </div>
          </template>
        </a-table>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:page-size="pageSize"
        :total="total"
        :show-total="total => `${total}条数据`"
        @change="currentChange"
      >
      </a-pagination>
    </div>
  </a-card>
  <add
    v-if="addShow"
    :modal-ref="$refs.modalContainer"
    @close="hideAddModal"
  ></add>
  <audit
    v-if="editShow"
    :id="currentId"
    :modal-ref="$refs.modalContainer"
    @close="hideEditModal"
  ></audit>
  <detail
    v-if="detailShow"
    :id="currentId"
    :modal-ref="$refs.modalContainer"
    @close="hideDetailModal"
  ></detail>
</template>

<script>
import CardHeader from "@/components/CardHeader";
import useRefundRecord from "@/hooks/Info/useRefundRecord";
import usePagination from "@/hooks/usePagination";
import useAddExit from "@/hooks/useAddExit";
import { onMounted, ref } from "vue";
import Audit from "@/views/Info/RefundRecord/components/Audit";
import Detail from "@/views/Info/RefundRecord/components/Detail";
import Add from "@/views/Info/RefundRecord/components/Add";
import TipButton from "@/components/TipButton";
import { saveAs } from 'file-saver'
import axios from "axios";
export default {
  name: "index",
  components: {
    Detail,
    Add,
    Audit,
    CardHeader,
    TipButton,
  },
  setup() {
    const {
      params,
      dataList,
      total,
      loading,
      columns,
      loadData,
      money,
      getChannel,
    } = useRefundRecord();

    const { page, pageSize, currentChange, doQuery, doRefresh } =
      usePagination(loadData);

    const {
      addShow,
      showAddModal,
      hideAddModal,
      currentId,
      editShow,
      showEditModal,
      hideEditModal,
    } = useAddExit(doQuery);

    const detailShow = ref(false);
    const showDetailModal = id => {
      currentId.value = id;
      detailShow.value = true;
    };

    const hideDetailModal = refresh => {
      detailShow.value = false;
      if (refresh) {
        doQuery();
      }
    };
    const download = () => {
      axios.get("/refundOrder/listExport",{
        params: {
          payOrderId: params.payOrderId,
          auditTimeArr: params.auditTimeArr ? params.auditTimeArr.toString() : [],
        },
        responseType: 'blob'
      }).then(res => {
        if(!res) return
        const blob = new Blob([res.data], { type: 'application/octet-stream' })
        saveAs(blob, decodeURIComponent(res.headers['download-filename']))
      }).catch((error) => {
        console.error(error)
      });
    };
    const plateColorStyle = val => {
      switch (val) {
            case 0:
                return "#888888";
            case 1:
                return "#0000FF";
            case 2:
                return "#FFA500";
            case 3:
                return "#000000";
            case 4:
                return "#FFFFFF";
            case 5:
                return "#008000";
            default:
                return "#FF0000";
        }
    };

    onMounted(async () => {
      loadData(page.value, pageSize.value);
    });

    return {
      params,
      page,
      pageSize,
      total,
      loading,
      currentChange,
      dataList,
      doQuery,
      doRefresh,
      columns,
      money,
      getChannel,
      plateColorStyle,

      currentId,
      editShow,
      showEditModal,
      hideEditModal,
      addShow,
      showAddModal,
      hideAddModal,

      detailShow,
      showDetailModal,
      hideDetailModal,
      download
    };
  },
};
</script>

<style scoped></style>
